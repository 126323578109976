import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Title from "../Title"
// import AniLink from "gatsby-plugin-transition-link/AniLink";
// import Blog from '../Blog/Blog';
import PageCard from "./PageCard"
import { Col, Container, Row } from "react-bootstrap"

const Categories = () => {
  // const { recipesBcg, blogBcg, weekmenuBcg } = useStaticQuery(getImages);
  const { recipesBcg, weekmenuBcg } = useStaticQuery(getImages)

  return (
    <Container fluid className="align-items-center pt-6 pb-6">
      <Title title="ga" subtitle="naar" />
      <Row data-aos="fade-up">
        <Col md={6} className="justify-content-center d-flex pb-3">
          <PageCard
            buttonText={"Recepten"}
            slug={"/recipes"}
            mainImage={recipesBcg.childImageSharp.fluid}
          />
        </Col>
        <Col md={6} className="justify-content-center d-flex pb-3">
          <PageCard
            buttonText={"Weekmenu's"}
            slug={"/weekmenu"}
            mainImage={weekmenuBcg.childImageSharp.fluid}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default Categories

const getImages = graphql`
  query {
    recipesBcg: file(relativePath: { eq: "recipesBcg.png" }) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 750) {
          srcWebp
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    weekmenuBcg: file(relativePath: { eq: "weekmenusBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 750) {
          srcWebp
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogBcg: file(relativePath: { eq: "blogBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 750) {
          srcWebp
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
