import React from 'react';
// import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Card, Col } from "react-bootstrap"

const PageCard = ({ buttonText, slug, mainImage }) => {
    return (
        <Col xl={8}>
            <Card className="text-white text-center justify-content-center">
                <Card.Img src={mainImage.srcWebp} alt={mainImage.originalName} />
                <Card.ImgOverlay className="text-white text-center justify-content-center align-items-center d-flex" style={{backgroundColor: "rgba(52, 86, 120, 0.5)"}}>
                    <Card.Link href={slug}><h1 className="h1-white">{buttonText}</h1></Card.Link>
                </Card.ImgOverlay>
            </Card>
        </Col>
    )
}

export default PageCard;
