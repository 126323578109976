import React from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import Categories from "../components/Home/Categories";
import Title from '../components/Title';
import { Col, Container } from "react-bootstrap";
import SEO from "../components/seo";

const ThankYouPage = () => (
  <PageWrapper>
    <SEO title="Bedankt!" />
    <Container className="d-flex justify-content-center under-nav text-center" fluid>
      <Col>
      <Title title="bericht" subtitle="verzonden" />
      <h3>Bedankt voor je berichtje!</h3>
      <Categories />
      </Col>
    </Container>
  </PageWrapper>
)

export default ThankYouPage;
